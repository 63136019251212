//common
.bg-purple {
    background-color: #e7e7e7;
}
.stick .main-menu {
    margin: 19px auto!important;
}
.stick .header-right-wrap {
    margin: 22px auto!important;
}
.d-none.d-lg-block.header-top-area {
    display: none!important;
}
.stick .logo{
    margin-top: 0px;
}
.logo {
    margin-top: 0px;
}
.logo img {
    width: 105px;
}
.main-menu {
    margin: 8px auto;
}
.main-menu nav ul li > a {
    text-transform: uppercase;
    font-size: 18px;
}
.main-menu nav ul li ul.submenu li a:hover {
    color: #fd8800;
}
.main-menu nav ul li ul.submenu li a::before{
    background: #fd8800 none repeat scroll 0 0;
}
.header-right-wrap{
    margin: 26px auto;
}
.stick .main-menu {
    margin: 25px auto;
}
.stick .header-right-wrap{
    margin: 45px auto;
}
.main-menu nav ul li:hover a {
    color: #fd8800;
}
.header-right-wrap .same-style:hover > a, .header-right-wrap .same-style:hover > button {
    color: #fd8800;
}
.single-slider .slider-content .slider-btn a:hover {
    border: 1px solid #fd8800;
}
.header-right-wrap .same-style.cart-wrap:hover > button, .header-right-wrap .same-style.header-wishlist:hover > button, .header-right-wrap .same-style.header-compare:hover > button{
    color: #fd8800;
}
//homepage
.btn-hover a:after, .btn-hover buutton:after{
    background: #fd8800;
}
.scroll-top{
    background-color: #fd8800;
}
.single-slider .slider-content {
    padding: 130px 0 0;
}
.single-slider .slider-single-img {
    margin: 0px;
}
.slider-height-1 {
    height: 620px;
}

.destination-tab-list .nav-item {
    width: 25%;
}
.destination-tab-list .nav-item a{
    display: flex;
    width: 335px;
    z-index: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    vertical-align: middle;
    transition: all .3s ease 0s;
    padding:0px;
}
.destination-tab-list .nav-item a .box-img{
    width:100%;
    margin:0px auto;
    text-align: center;
    float:left;
    margin-bottom: 10px;
}
.destination-tab-list .nav-item a img {
    margin: 0px auto;
    justify-content: center;
    text-align: center;
    max-width: 50%;
    float: initial;
}
.destination-tab-list a h4 {
    vertical-align: middle;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.3s ease 0s;
    color: #555;
    float: left;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 5px;
    width: 100%;
}
.destination-tab-list a p {
   
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
}
.destination-tab-list .nav-item a:hover h4{
    color: #fd8800;
}
.destination-tab-list a.active h4{
    color: #fd8800;
}
.destination-tab-list .nav-item.active a:hover h4{
    color: #fd8800;
}



.product-tab-list .nav-item a{
    display: flex;
    width: 335px;
    z-index: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    vertical-align: middle;
    transition: all .3s ease 0s;
    padding:0px;
}
.product-tab-list .nav-item a .box-img{
    width:100%;
    margin:0px auto;
    text-align: center;
    float:left;
    margin-bottom: 10px;
}
.nav-item:hover .my-box {
    background: #f1f1f1;
}
.product-tab-list .nav-item a img {
    margin: 0px auto;
    justify-content: center;
    text-align: center;
    max-width: 50%;
    float: initial;
}
.product-tab-list a h4 {
    vertical-align: middle;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.3s ease 0s;
    color: #555;
    float: left;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 5px;
    width: 100%;
}
.product-tab-list a p {
   
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
}
@keyframes spin { 
    100% { 
      transform: rotateZ(360deg); 
    }
  }
.nav-pills .nav-link.active{
    border: 2px dashed #fd8800;
    color:#fd8800;
    background: 0 0;
}

.product-tab-list .nav-item a:hover h4{
    color: #fd8800;
}
.product-tab-list a.active h4{
    color: #fd8800;
}
.product-tab-list .nav-item.active a:hover h4{
    color: #fd8800;
}
.myinputbox{
    width: 100%;
    float: left;
}
.myinputbox input {
    width: 100px;
    float: right;
}
.product-img-badges {
    display: none;
}
.pro-same-action.pro-cart {
    float: left;
    margin: 0px auto;
}
.product-wrap .product-img .product-action > div, .product-list-image-wrap .product-img .product-action > div{
    background-color: #fd8800;
}

.product-rating {
    display: none;
}
.product-price {
    display: none;
}
.tab-content {
    width: 100%;
    float: left;
    margin: 40px 0px;
}
.tab-content > .tab-pane {
    height: 490px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.my-box {
    width: 100%;
    float: left;
    margin: 0px;
    background-size: cover;
    background-position: center;
    height: 100%;
    padding: 10px;
    border: 1px solid #f2f2f2;
    border-radius: 15px;
}
.my-box img{
    width: 100%;
    float: left;
    margin: 0px;
    background-size: cover;
    background-position: center;
    height: 100%;
    padding: 0px;
}

//sticky

#fixed-form-container {
    position: fixed;
    bottom: 0px;
    width: 25%;
    left: 30px;
    text-align: center;
    margin: 0;
    z-index: 9999;
}
.book-popup {
    background-color: rgba(0,0,0,0.8);
    padding: 11px 22px;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    max-height: 400px;
}
/*header dropdown*/
.header-offer {
    display: none;
}
.same-language-currency.use-style {
    display: none;
}
.same-language-currency p {
    display: none;
}
.header-top-area {
    border-bottom: none;
}
.same-language-currency.language-style {
    margin: 0px;
   
}
.header-padding-1.header-top-area {
    margin: 25px 0px;
    width: 33%;
    display: inline-block;
}
.contact-social.text-center {
    margin: 11px auto;
    display: inline-block;
    width: 67%;
}
.language-currency-wrap .same-language-currency:hover > a, .language-currency-wrap .same-language-currency:hover span{
    color:#fd8800;
}
.language-currency-wrap .same-language-currency:before{
 right: -10px;   
}
.contact-social ul li{
    margin: 4px 4px;
}
.contact-social.text-center i {
    font-size: 13px;
}
.contact-social ul li a{
    margin: 0 5px;
}


/*banner new 4*/
.slider-content-brown h3 {
    font-size: 50px;
}
.slider-area {
    height: 600px!important;
}
.slider-height-2 {
    height: 600px;
}
.contact-form .contact-form-style button {
    margin: 0px;
    padding: 15px 35px;
}
.contact-form .contact-form-style input{
    margin-bottom: 0px;
}
.myinputbox.pb-20.book-popup h4 {
    color: #fff;
    width: 100%;
    float: left;
    margin-top: 3px!important;
}
.myinputbox.pb-20.book-popup button{
    width: 30%;
    float: right;
}
.tab-pane .product-img {
    width: 160px;
    height: 160px;
    margin:0px auto;
    text-align: center;
    border-radius: 17px;
    border: 2px solid #babbb9;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
img.hover-img{
    display: none;
}
.product-action {
    width: 100%;
    float: left;
    text-align: center;
    margin: 0px;
}
.pro-same-action.pro-cart {
    float: left;
    margin: 0px auto;
    width: 100%!important;
    text-align: center;
    padding: 0px 24px;
}.pro-cart .cart-plus-minus {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 95%;
    height: 25px;
    padding: 0px;
}
button.dec.qtybutton:hover{
    background: #fd8800!important;
    color: #fff!important;
}
button.inc.qtybutton:hover{
    background: #fd8800!important;
    color: #fff!important;
}

/*footer*/
.copyright .footer-logo {
    margin-top: 0px;
}
.footer-logo img {
    width: 140px;
}

/*Contact*/
.contact-info-wrap {
    padding: 47px 70px 116px 90px;
}
.my-contact-page .contact-form-style input, .my-contact-page .contact-form-style textarea{
    margin-bottom: 8px!important;
}
.contact-form .contact-form-style button:hover {
    background-color: #fd8800;
}
.contact-social ul li a:hover {
    color: #fd8800;
}

// darshan css
.pro-cart{
    display:block;
    align-items: center !important;
    margin-top: 10px !important;
    // margin-bottom: 34px !important;
    width: 70% !important;
}

.pro-cart .cart-plus-minus {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 70%;
    height: 25px;
    padding: 0;
    // border: 1px solid #e8e8e8;
  }

.pro-cart .cart-plus-minus .dec.qtybutton {
    top: 0;
    left: 0;
    height: 25px;
    border: 1px solid #000 !important;
    border-radius: 2px !important;
  }

.pro-cart .cart-plus-minus input.cart-plus-minus-box {
    font-size: 14px;
    float: left;
    width: 100%;
    height: 25px;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #8f8f8f;
    border: medium none;
    background: transparent none repeat scroll 0 0;
  }

.pro-cart .cart-plus-minus .inc.qtybutton {
    top: 0;
    right: 0;
    height: 25px;
    border: 1px solid #000 !important;
    border-radius: 2px !important;
  }

.pro-cart .cart-plus-minus .qtybutton {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    position: absolute !important;
    float: inherit !important;
    width: 24px !important;
    margin: 0 !important;
    cursor: pointer !important;
    transition: all 0.3s ease 0s !important;
    text-align: center !important;
    color: #8f8f8f !important;
    border: none !important;
    background: none !important;
  }

  //tab menu css
  /* Style the tab */
  .tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
  }
  
  /* Style the buttons inside the tab */
  .menu-tabs .menu-tab button{
    padding: 5px !important;
  }

  select {
    height: 40px;
    padding: 2px 14px;
    color: #000;
    border: 1px solid #c1c1c1;
    background: transparent;
  }

  .book-popup
  {
    display: none;
  }

  .slider-content-2 {
    margin-top: -150px !important;
  }

@media only screen and (max-width: 767px) {

    // .label-float label {
    //     top: calc(50% - 13px) !important;
    // }

    // .modal-dialog {
    //     width: 100% !important;
    //     max-width: 100% !important;
    //     margin: 0% auto !important;
    //   }

    .package-btn{
        padding: 4px 15px !important;
    }

    .book-popup{
        padding: 10px 22px !important;
    }
    .myinputbox.pb-20.book-popup h4 {
        font-size: 15px !important;
        line-height: 16px !important;
    }

    .scroll-top {
        right: 0px !important;
        left: 20px !important;
    }

    .section-title h2 {
        font-size: 23px !important;
      }

    .loc_details, .loc_details p {
        font-size: 12px;
        padding-bottom: 25px;
      }

    .plan-journey-block h2 {
        font-size: 24px !important;
        line-height: 30px;
      }

    select{
        margin-bottom: 10px;
    }
    #fixed-form-container {
        width: 100%;
        left: 0px;
    }
    .tab-pane .col-xl-2.col-md-6.col-lg-4.col-sm-6 {
        flex: 1 0 50%;
        max-width: 49%;
        transition: 0.5s;
    }
    .loading {
        margin: -40px 0 0 -70px!important;
    }
    .product-tab-list.pt-30, .product-tab-list.pt-55 {
        padding: 10px 0 0px;
    }
    .destination-tab-list.pt-30, .destination-tab-list.pt-55 {
        padding: 10px 0 0px;
    }
    .header-right-wrap .contact-social{
        display: none;
    }
    .same-style.cart-wrap.d-block.d-lg-none {
        display: none!important;
    }
    .header-top-wap {
        display: none;
    }
    .header-res-padding {
        padding: 2px 0 2px;
    }
    .header-right-wrap .same-style > a, .header-right-wrap .same-style button{
        font-size: 33px;
    }
    button.mobile-aside-button i {
        margin: 10px auto;
    }
    .stick .header-right-wrap {
        margin: 25px auto;
    }

    /*tab responsive*/
    .product-tab-list .nav-item {
        width: 33%;
    }
    .product-tab-list .nav-item a {
        width: 100%;
        margin: 0px;
    }
    .my-box p p {
        padding: 0px 8px;
    }
    .product-tab-list .nav-item a img{
        max-width:100%;
    }


    /*tab responsive*/
    .destination-tab-list .nav-item {
        width: 25%;
    }
    .destination-tab-list .nav-item a {
        width: 100%;
        margin: 0px;
    }
    .my-box p p {
        padding: 0px 8px;
    }
    .destination-tab-list .nav-item a img{
        max-width:100%;
    }

    /*home*/
    .slider-height-2 {
        height: 420px;
    }
    .slider-area {
        height: 420px!important;
    }
    .slider-content-brown h3 {
        font-size: 28px;
    }
    .contact-form .contact-form-style input {
        margin-bottom: 10px;
    }
    button.submit {
        width: 100%;
    }
    .offcanvas-widget-area {
        margin-bottom: 65px;
    }
    .my-box p {
        font-size: 13px;
    }
    .offcanvas-mobile-search-area {
        display: none;
    }
    .maplibregl-map.mapboxgl-map {
        height: calc(60vh - 100px)!important;
    }
    // .my-box p p {
    //     display: none;
    // }
    .footer-logo {
        display: none;
    }
}

.e-marker {
    transform-origin: bottom center;
    cursor: pointer;
}
.e-marker__marker {
    fill: #fff;
}
.e-marker__icon {
    fill: #fff;
    transform-origin: 50% 50%;
}
.e-marker__image {
    // transform: scale(0);
    transform-origin: 50% 50%;
}
.e-marker__circle {
    display: none;
    fill: #ec008c;
    opacity: 0.5;
}
.e-marker__loader {
    fill: #fff;
    transform-origin: 50% 50%;
    transform: scale(0);
}
.e-marker__text {
    font-size: 12px;
    font-family: brandon-grotesque, sans-serif;
    text-align: center;
    opacity: 0;
    color: #fff;
    fill: #fff;
    transform-origin: 50% 50%;
    text-anchor: middle;
}


//darshan css
.cart-tbl{
    color:white;
}

.footer-content-row{
    justify-content:center !important;

}
.modal-body img{
    height: 65%;
    width: 100%;
}

.plan-journey-block h2 {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    display: inline-block;
    margin: 0 0 0;
    text-align: center;
  }

.preloader-custom{
    display: none;
}

.preloader-inner {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    background-color: rgba(2, 48, 71, 0.9);
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .spinner {
    margin: 120px auto;
    width: 60px;
    height: 60px;
    position: relative;
    text-align: center;
    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear;
  }

  .dot1, .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: orange;
    border-radius: 100%;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }

  .dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -60px 0 0 -60px;
    background: #fff;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 1px dotted rgba(215, 215, 215, 1);
  }
  .loading img{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%)translateY(-50%);
  }
  .loading:after {
    content: '';
    background: trasparent;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 100%;
    top: -0%;
    left: -0%;
    opacity: 1;
    box-shadow: rgba(230, 170, 28, 0.85) -4px -5px 3px -3px;
    animation: rotate 2s infinite linear;
  }
  
  @keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
//   .modal-dialog
//   {
//     margin:0% auto !important;
//     width: 700px !important;
//     max-width: 700px !important;
//   }

  .modal-dialog .modal-body {
    padding: 10px 15px !important;
  }

  .cart-wrap {
    display: none !important;
    }
  #how-its-work-tab img
    {
        height: 100%;
    }
    #how-its-work-tab p {
        font-size: 11px !important;
        line-height: 15px !important;
        margin-bottom: 15px !important;
      }
    .pay-default-img{
        width: 65%;
        height: 80%;
    }

    #free-supplier-block
    {
        height: 200px !important;
    }

    .how-its-work-tab .tab-pane .product-img{
        height:120px !important;
    }
    @media only screen and (max-width: 330px) {
    .tab-pane .product-img {
        width: 130px!important;
        height: 130px!important;
    }
    .pro-cart .cart-plus-minus{
        width: 90%!important;
    }
    

}
@media only screen and (max-width: 400px){
.how-its-work-tab .tab-pane .product-img {
    width: 100%!important;
}
.product-area .tab-pane .product-img {
    width: 100%;
    height: 131px;
}
.pay-default-img {
    width: 100%;
    height: auto;
    margin-top: 10px;
}
}
.how-its-work-tab .tab-pane .product-img {
    height: 120px;
}
.product-wrap p {
    margin-top: 10px;
}

.scroll-top
{
    background-color: #0dc143 !important;
    height: 55px !important;
    width:  55px !important;
    border-radius:  10px !important;

}

.scroll-top i
{
    color: #fff !important;
    font-size:45px !important;
    line-height: 55px !important;
}

.scroll-top:hover i
{
    animation-name:none !important;
    color: #fff !important;
}

.icons-font
{
    font-size: 11px !important;
    color:#808080;
}

.package-btn{
    margin: 0px;
    padding: 4px 35px;
    font-size: 14px;
    color:white;
    background-color: #64848f;
    border-radius: 20px;
    border: 0px;
}

// new code added by dc on 22-08-23

.main-menu nav ul li a
{
    text-transform: none !important;
    font-size: 16px !important;
}

.destination-tab-list .my-box{
    border: none !important;
}
.destination-tab-list .package-btn{
    padding: 4px 15px;
}
.package_title
{
    font-size: 20px !important;
    color:#111111;
}

.product-tab-list .nav-item
{
    padding: 2px;
}
.product-tab-list p
{
    margin-bottom: 0px;
    font-family: "Roboto", sans-serif;
}
.package_sub_title {
    text-align: left;
    margin-left: 15px;
    color:#808080;
  }
  .package_subloc_title {
    text-align: left;
    margin-left: 15px;
    color:#111111;
  }
.text-gold
{
    color:#e2b856;
}
.price_title
{
    font-size: 22px;
    color:#111111;
}
.package_price{
    color:#808080;
}

input[type="checkbox"] {
    height: 15px !important;
    margin: 0 !important;
    position: relative !important;
    top: 1px !important;
    width: 17px !important;
  }
.check-label
{
    font-size: 13px;
}

.footer-area
{
    background: #363839;
    font-family: "Arial", sans-serif;
}
.footer-widget .footer-title h3{
    font-family: "Arial", sans-serif;
    color:#dddddd !important; 
    text-decoration: underline;
}
.footer-widget .footer-list ul li {
    color:#CECECE !important; 
}
.footer-widget .footer-list ul li a{
    color:#CECECE !important; 
}
.footer-widget .footer-list p{
    color:#CECECE !important;
    // text-align: justify;
    font-family: "Arial", sans-serif;
}
.footer_copy
{
    background: #282a2b;
    font-family: "Arial", sans-serif;
}
.footer_copy p{
    color:#CECECE !important;
    // text-align: justify;
    font-family: "Arial", sans-serif;
}
.package_col1
{
    width: 300px;
    padding: 2px 5px 2px 5px;
    font-weight: bold;
}
.package_col2
{
    width: 435px;
    padding: 2px 5px 2px 5px;
}
.package_col3
{
    width: 435px;
    padding: 2px 5px 2px 5px;
}
.package_col4
{
    width: 435px;
    padding: 2px 5px 2px 5px;
}
.package_table tr,td{
    border-width: 1px !important;
}
.contact-social
{
    width: 100% !important;
}
.share-icon {
    margin-bottom: 65px;
}
.scroll-top.share-icon.show {
    background: #ff5151 !important;
}
.footer-share-icon li
{
    margin: 3px !important;
}
.package_table i{
    font-size: 22px !important;
}
.off-canvas-widget-social a{
    margin: 4px !important;
}